import React from 'react';

import naraigoto from 'static/lp/new_kakekko.jpg';
import watataka_yoko from 'static/lp/watataka_yoko.png';
// import question from 'static/question.jpg';

export const SkyBackgroundSummary = ({ isSmartPhone = true }) => {
  return (
    <div style={{ marginTop: '-10px' }}>
      {(isSmartPhone && <img src={naraigoto} alt="" width="100%" />) || (
        <img src={watataka_yoko} alt="" width="100%" />
      )}
    </div>
  );
};
