import React from 'react';
import classes from '../fifty_check.module.css';
import title from 'static/lp/title_output.png';
import masonry from 'static/lp/masonry.png';

export const OutputSample = () => {
  return (
    <div className={classes.output}>
      <div style={{ paddingTop: '16px' }}>
        <img src={title} alt="output" width="70%"></img>
      </div>
      <div style={{ marginTop: '16px' }}>
        <img src={masonry} alt="output" width="95%"></img>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <br />
      </div>
    </div>
  );
};
