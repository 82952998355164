import React from 'react';

import { useWindowSize } from 'hooks/useWindowSize';

import { WiderPurchasePage } from './WiderPurchasePage';
import { PurchasePageMobile } from './components/PurchasePageMobile';

export const PurchasePage = () => {
  const size = useWindowSize();

  console.log(size);

  const isSmartPhoneView = size[0] < 500 || size[1] / size[0] > 1.5;

  if (isSmartPhoneView) {
    return <PurchasePageMobile />;
  }
  return <WiderPurchasePage size={size} />;
};

type sizeType = number[];
export const calculateVideoScreen = (size: sizeType) => {
  return {
    width: Math.trunc(size[0] * 0.9),
    height: Math.trunc(size[0] * 0.6),
  };
};
