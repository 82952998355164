import React from 'react';
import { Grid } from '@mui/material';
import classes from '../purchasepage.module.css';
import grade_demo from 'static/grade_demo.png';

export const SalesPoints = ({ isSmartPhone = true }) => {
    if (!isSmartPhone) {
        return (
            <div style={{ backgroundColor: '#edffdb', paddingTop: '20px' }}>
                <div className={classes.title}>楽しみながら続けるための</div>
                <div className={classes.title}>しかけが盛りだくさん！</div>
                <div style={{ marginTop: '20px' }}>
                    <Grid container>
                        <Grid item xs={6}>
                            <div style={{ padding: '8px' }}>
                                <div className={classes.semititle}>
                                    <div style={{ marginTop: '20px' }}>
                                        ①みんな夢中になる昇級システム！
                                    </div>
                                    <div>
                                        各級の
                                        <span style={{ color: '#dc143c' }}>
                                            昇級テスト
                                        </span>
                                        をクリアしよう！
                                    </div>

                                    <div>
                                        <br />
                                    </div>
                                </div>
                                <img
                                    src={grade_demo}
                                    alt="昇級"
                                    width="60%"
                                ></img>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ padding: '8px' }}>
                                <div className={classes.semititle}>
                                    <div style={{ marginTop: '20px' }}>
                                        ②カレンダーとスタンプ機能！
                                    </div>
                                    <div>
                                        たくさん練習してスタンプをたくさん集めよう！
                                    </div>

                                    <div>
                                        <br />
                                    </div>
                                </div>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: ` <video
                  width="60%"
                  autoplay
                  muted
                  playsinline
                  loop
                  poster="https://shimotakaido.s3.amazonaws.com/lesson_videos/online_school_thumbnail.png"
                >
                <source src="https://shimotakaido.s3.amazonaws.com/lesson_videos/sticker_demo.mp4" />
                </video>`,
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }

    return (
        <div style={{ backgroundColor: '#edffdb', paddingTop: '20px' }}>
            <div className={classes.title}>楽しみながら続けるための</div>
            <div className={classes.title}>しかけが盛りだくさん！</div>
            <div style={{ marginTop: '20px' }}>
                <Grid container>
                    <Grid item xs={6}>
                        <div className={classes.semititle}>
                            <div style={{ marginTop: '80px' }}>
                                楽しんで続く
                            </div>
                            <div>スタンプ機能！</div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ padding: '8px' }}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: ` <video
                  width="100%"
                  autoplay
                  muted
                  playsinline
                  loop
                  poster="https://shimotakaido.s3.amazonaws.com/lesson_videos/online_school_thumbnail.png"
                >
                <source src="https://shimotakaido.s3.amazonaws.com/lesson_videos/sticker_demo.mp4" />
                </video>`,
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>

            <div style={{ marginTop: '20px' }}>
                <Grid container>
                    <Grid item xs={6}>
                        <div style={{ padding: '8px' }}>
                            <img src={grade_demo} alt="昇級" width="99%"></img>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.semititle}>
                            <div style={{ marginTop: '50px' }}>
                                みんな夢中になる
                            </div>
                            <div>昇級システム！</div>
                            <div style={{ marginTop: '40px' }}>
                                各級で出される
                            </div>
                            <div style={{ color: '#dc143c' }}>昇級テスト</div>
                            <div>をクリアしよう！</div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
