import React, { FC } from 'react';
import { SalesPoints } from './components/SalesPoints';
import { CustomerReview } from './components/CustomerReview';
import { ProgramExplanation } from './components/ProgramExplanation';
import { SkyBackgroundSummary } from './components/SkyBackgroundSummary';
import { Schedule } from './components/Schedule';
import { FixBarButton } from './components/FixBar';
import { ApplyExplanation } from './components/ApplyExplanation';
import { Inquiry } from './components/Inquiry';
import { AppliStrength } from './components/AppliStrength';

type Props = {
    size: number[];
};

export const WiderPurchasePage: FC<Props> = ({ size }) => {
    return (
        <div style={{ textAlign: 'center', fontFamily: 'Arial' }}>
            <div style={{ display: 'flex' }}>
                <div style={{ width: '10vw' }}></div>
                <div style={{ width: '80vw' }}>
                    <ProgramExplanation isSmartPhone={false} />
                    {/* <YouAreTarget /> */}

                    <SkyBackgroundSummary isSmartPhone={false} />

                    <div style={{ marginTop: '20px' }}>
                        <AppliStrength isSmartPhone={false} />
                    </div>

                    <div style={{ marginTop: '20px' }}>
                        <SalesPoints isSmartPhone={false} />
                    </div>
                    <CustomerReview isSmartPhone={false} />
                    {/* <TryFree isSmartPhone={false} /> */}

                    <div style={{ marginTop: '50px' }}>
                        <Schedule isSmartPhone={false} />
                    </div>
                    <div id="applyForm" style={{ backgroundColor: '#ffffff' }}>
                        <ApplyExplanation isSmartPhone={false} />
                        {/* <PurchaseExplanation isSmartPhone={false} /> */}
                    </div>

                    <div style={{ marginTop: '20px', width: '100%' }}>
                        <Inquiry />
                    </div>
                </div>
                <div style={{ width: '10vw' }}></div>
            </div>
            <FixBarButton />
        </div>
    );
};

type sizeType = number[];
export const calculateVideoScreen = (size: sizeType) => {
    return {
        width: Math.trunc(size[0] * 0.9),
        height: Math.trunc(size[0] * 0.6),
    };
};
