import React from 'react';

import { SalesPoints } from './SalesPoints';
import { CustomerReview } from './CustomerReview';

import { ProgramExplanation } from './ProgramExplanation';
import { SkyBackgroundSummary } from './SkyBackgroundSummary';
// import { PurchaseQA } from 'pages/UnmemberPages/components/PurchasePageQA';
import { Schedule } from './Schedule';
import { FixBarButton } from './FixBar';
import { ApplyExplanation } from './ApplyExplanation';
import { Inquiry } from './Inquiry';
import { AppliStrength } from './AppliStrength';

export const PurchasePageMobile = () => {
    return (
        <div style={{ textAlign: 'center', fontFamily: 'Arial' }}>
            <ProgramExplanation />
            {/* <YouAreTarget /> */}

            <SkyBackgroundSummary />

            <div style={{ marginTop: '20px' }}>
                <AppliStrength />
            </div>

            <div style={{ marginTop: '20px' }}>
                <SalesPoints />
            </div>
            <CustomerReview />
            {/* <TryFree /> */}

            <div style={{ marginTop: '50px' }}>
                <Schedule />
            </div>
            {/* <div style={{ marginTop: '50px' }}>
        <CustomerReview />
      </div>  */}
            <div
                id="applyForm"
                style={{ backgroundColor: '#ffffff', marginTop: '50px' }}
            >
                <ApplyExplanation isSmartPhone={true} />
            </div>

            {/* <div style={{ marginTop: '50px' }}>
        <PurchaseQA />
      </div> */}
            <div style={{ marginTop: '20px', width: '100%' }}>
                <Inquiry />
            </div>
            <FixBarButton />
        </div>
    );
};

type sizeType = number[];
export const calculateVideoScreen = (size: sizeType) => {
    return {
        width: Math.trunc(size[0] * 0.9),
        height: Math.trunc(size[0] * 0.6),
    };
};
