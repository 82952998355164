import React, { useCallback } from 'react';
import { Button } from '@mui/material';
import classes from '../purchasepage.module.css';
import { useNavigate } from 'react-router-dom';

export const FixBarButton = () => {
    const navigate = useNavigate();

    const goToRegister = useCallback(() => {
        navigate('/register');
    }, [navigate]);

    return (
        <div className={classes.fixBar}>
            <div style={{ padding: '12px' }}>
                <Button
                    color="primary"
                    variant="contained"
                    size="medium"
                    onClick={goToRegister}
                >
                    まずは無料で登録する！
                </Button>
            </div>
        </div>
    );
};
