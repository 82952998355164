import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PurchasePage } from 'pages/PurchasePage';
import { FiftyCheckLP } from 'pages/FiftyCheckLP/FiftyCheckLP';

export const Router = () => {
    return (
        <>
            <Routes>
                <Route path="/purchase" element={<PurchasePage />} />
                <Route path="/introduction" element={<FiftyCheckLP />} />
                <Route path="/" element={<PurchasePage />} />
            </Routes>
        </>
    );
};
