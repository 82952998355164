import React from 'react';
import classes from '../purchasepage.module.css';
// import calendar_example from 'static/lp/calendar_example.png';
import busy_woman from 'static/lp/busy_woman.jpg';
import happy_woman from 'static/lp/happy_woman.png';

export const Schedule = ({ isSmartPhone = true }) => {
    if (!isSmartPhone) {
        return (
            <>
                <div>
                    <img src={busy_woman} width="70%" alt="忙しい女性" />

                    <div
                        style={{
                            textAlign: 'center',
                            fontWeight: 'bold',
                            color: '#404040',
                            fontSize: '18pt',
                            marginTop: '30px',
                        }}
                    >
                        そんな不安も
                        <span style={{ color: '#3cb371' }}>
                            オンラインならでは
                        </span>
                        の
                        <br />
                        スケジュールが解決！
                    </div>

                    <div
                        style={{
                            padding: '12px',
                            marginTop: '16px',
                            display: 'flex',
                        }}
                    >
                        <div
                            style={{
                                marginTop: '16px',
                                width: '30%',
                                textAlign: 'right',
                            }}
                        >
                            <img
                                src={happy_woman}
                                width="40%"
                                alt="解決した女性"
                            />
                        </div>

                        <div
                            style={{
                                textAlign: 'left',
                                width: '70%',
                                marginLeft: '5%',
                                marginTop: '40px',
                                fontWeight: 'bold',
                                color: '#404040',
                                fontSize: '14pt',
                            }}
                        >
                            ☆ 家の前や近所の公園でできるので送り迎えいらず！
                            <br />
                            <br />☆ 習い事の
                            <span style={{ color: '#3cb371' }}>
                                スキマ時間にパッと
                            </span>
                            できる！
                        </div>
                    </div>
                </div>

                <div style={{ backgroundColor: '#f5f5f5', padding: '10px' }}>
                    <div
                        className={classes.title}
                        style={{ marginTop: '20px' }}
                    >
                        ご利用期間中のスケジュール
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: '48vw' }}>
                            <div
                                style={{
                                    borderRadius: '10px',
                                    backgroundColor: '#00bfff',
                                    marginLeft: '10%',
                                    width: '40%',
                                    color: 'white',
                                    fontWeight: 'bold',
                                }}
                            >
                                月曜日
                            </div>
                            <div
                                className={classes.semititle}
                                style={{ marginTop: '16px' }}
                            >
                                コーチからの コメントを確認！
                            </div>
                            <div style={{ marginTop: '16px' }}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: ` <video
                  width="65%"
                  autoplay
                  muted
                  loop
                  playsinline
                  poster="https://shimotakaido.s3.amazonaws.com/lesson_videos/online_school_thumbnail.png"
                >
                <source src="https://shimotakaido.s3.amazonaws.com/lesson_videos/coach_comment_sample.mp4" />
                </video>`,
                                    }}
                                />
                            </div>
                        </div>

                        <div style={{ width: '48vw', marginLeft: '2vw' }}>
                            <div
                                style={{
                                    borderRadius: '10px',
                                    backgroundColor: '#00bfff',
                                    marginLeft: '10%',
                                    width: '40%',
                                    color: 'white',
                                    fontWeight: 'bold',
                                }}
                            >
                                火曜日 - 日曜日
                            </div>
                            <div
                                className={classes.semititle}
                                style={{ marginTop: '16px' }}
                            >
                                おすすめされた 練習に取り組む！
                            </div>

                            <div style={{ marginTop: '16px' }}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: ` <video
                  width="65%"
                  autoplay
                  muted
                  playsinline
                  loop
                  poster="https://shimotakaido.s3.amazonaws.com/lesson_videos/online_school_thumbnail.png"
                >
                <source src="https://shimotakaido.s3.amazonaws.com/lesson_videos/practice_sample.mp4" />
                </video>`,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    return (
        <>
            <div>
                <img src={busy_woman} width="100%" alt="忙しい女性" />

                <div
                    style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        color: '#404040',
                        fontSize: '16pt',
                    }}
                >
                    そんな不安も
                    <span style={{ color: '#3cb371' }}>オンラインならでは</span>
                    <br />
                    のスケジュールが解決！
                </div>

                <div
                    style={{
                        display: 'flex',
                        padding: '12px',
                        marginTop: '16px',
                    }}
                >
                    <div style={{ width: '17vw' }}>
                        {' '}
                        <div style={{ marginTop: '16px' }}>
                            <img
                                src={happy_woman}
                                width="90%"
                                alt="忙しい女性"
                            />
                        </div>
                    </div>
                    <div style={{ width: '82vw' }}>
                        {' '}
                        <div
                            style={{
                                textAlign: 'left',
                                paddingLeft: '8px',
                                paddingRight: '6px',
                                fontWeight: 'bold',
                                color: '#404040',
                                fontSize: '12pt',
                            }}
                        >
                            ☆ 家の前や近所の公園でできるので送り迎えいらず！
                            <br />
                            <br />☆ 習い事の
                            <span style={{ color: '#3cb371' }}>
                                スキマ時間にパッと
                            </span>
                            できる！
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
