import React from 'react';
import classes from './fifty_check.module.css';
import diagnose from 'static/lp/diagnose.png';
import idaten from 'static/lp/idaten_way.png';
import family from 'static/lp/family.png';
import { DiagnoseButton } from './components/diagnoseButton';
import { DiagnoseProcedure } from './components/DiagnoseProcedure';
import { LPFooter } from './components/LPFooter';
import { OutputSample } from './components/OutputSample';

export const FiftyCheckLP = () => {
  return (
    <>
      <div className={classes.background}>
        <div style={{ marginLeft: '4vw', paddingTop: '4vw' }}>
          <img src={idaten} width="50%" alt="idaten式" />
        </div>
        <div style={{ textAlign: 'center', marginTop: '16px' }}>
          <img src={diagnose} width="90%" alt="50m診断" />
        </div>
        <div style={{ textAlign: 'center', marginTop: '0px' }}>
          <img src={family} width="90%" alt="走ってる家族" />
        </div>
        <div style={{ textAlign: 'center', marginTop: '0px' }}>
          <DiagnoseButton />
        </div>

        {/* <div className={classes.normalText}>
          <ul>
            <li>
              東京大学のスポーツ科学特任研究員・修士学生がプログラムを監修しています。
            </li>
            <li style={{ marginTop: '12px' }}>
              お子さまの走りを姿勢・足の動き・腕ふりに分けてタイプ分けを行います。各タイプごとにアドバイスややるべき練習を提案します。
            </li>
            <li style={{ marginTop: '12px' }}>
              診断には株式会社Bluezoneの開発したAIを利用しています。トップアスリートにも愛用頂いてるものを小学生向けにチューニングしております。
            </li>
          </ul>
        </div> */}
        <div style={{ marginTop: '30px' }}>
          <br />
        </div>
      </div>
      <div style={{ marginTop: '24px' }}>
        <DiagnoseProcedure />
      </div>
      <div style={{ marginTop: '24px' }}>
        <OutputSample />
      </div>
      <div style={{ marginTop: '24px' }}>
        <LPFooter />
      </div>
    </>
  );
};
