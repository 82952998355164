import React, { FC } from 'react';

import top_image from 'static/lp/top_image3.png';
import top_image_yoko from 'static/top_image_yoko.svg';
import { useNavigate } from 'react-router-dom';

type Props = {
    // eslint-disable-next-line
    isSmartPhone?: boolean;
};

export const ProgramExplanation: FC<Props> = ({ isSmartPhone = true }) => {
    const navigate = useNavigate();

    return (
        <div style={{ textAlign: 'center' }}>
            <div>
                {(isSmartPhone && (
                    <img src={top_image} alt="" width="100%" />
                )) || <img src={top_image_yoko} alt="" width="100%" />}
                {isSmartPhone && (
                    <div>
                        <div style={{ margin: '8px' }}></div>
                        <div
                            onClick={() => navigate('/login')}
                            style={{
                                margin: '8px',
                                borderRadius: '4px',
                                color: 'white',
                                backgroundColor: '#4169e1',
                                padding: '3px',
                                fontSize: '9pt',
                            }}
                        >
                            ログイン
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
