import React from 'react';

import review from 'static/customer_review.png';
import review_yoko from 'static/customer_review_yoko.png';

export const CustomerReview = ({ isSmartPhone = true }) => {
  if (!isSmartPhone) {
    return (
      <div style={{ marginTop: '24px' }}>
        <img src={review_yoko} alt="check" width="95%"></img>
      </div>
    );
  }
  return (
    <div style={{ marginTop: '24px' }}>
      <img src={review} alt="check" width="99%"></img>
    </div>
  );
};
